<template>
  <div>
    <v-card>
      <v-card-text>
        <div class="emphasis" style="text-align: center">
          Congratulations!<br />
          Your account is ready.
        </div>
        <div class="mt-2 emphasis" style="text-align: center">
          Please link your current loan to get started:
        </div>
      </v-card-text>
      <v-card-actions>
        <div class="separator"></div>
        <v-btn color="secondary" to="/link-my-loan" width="100%">
          Link My Loan
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-card class="mt-3">
      <v-card-text class="pa-2 pb-6">
        <div class="emphasis ta-c pb-6 pt-2">Apply for a new loan now!</div>
        <v-card-actions class="pa-0">
          <v-btn color="green" to="/apply-now" width="100%"
            ><span style="color: white">Apply Now</span></v-btn
          >
        </v-card-actions>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'AccountIsReady',
};
</script>

<style>
.separator {
  font-size: 15px;
  font-weight: 600;
  margin: 24px auto 18px auto;
  position: relative;
  overflow: hidden;
  width: 100px;
  color: rgba(0, 0, 0, 0.54);
}
.separator span {
  display: inline-flex;
  position: relative;
  padding: 0 8px;
  z-index: 9999;
}
.separator span::before {
  right: 100%;
  content: '';
  display: block;
  width: 30px;
  position: absolute;
  top: 10px;
  border-top: 1px solid rgba(0, 0, 0, 0.16);
}
.separator span::after {
  left: 100%;
  content: '';
  display: block;
  width: 30px;
  position: absolute;
  top: 10px;
  border-top: 1px solid rgba(0, 0, 0, 0.16);
}
::after,
::before {
  box-sizing: border-box;
}
</style>
