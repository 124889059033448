import { loanService } from '../../services/loan.service';

const state = {
  loans: [],
  loading: true,
};

const getters = {
  loading: (s) => s.loading,
  loans: (s) => s.loans,
  hasLinkedLoans: (s) =>
    s.loans !== null && s.loans !== 'undefined' && s.loans.length > 0,
};

const actions = {
  clear: ({ commit }) => {
    commit('clear');
  },
  getLoans: ({ commit }, userId) => {
    commit('setLoading', true);
    return new Promise((resolve, reject) => {
      loanService
        .getLoans(userId)
        .then(
          (result) => {
            if (result.succeeded) {
              commit('addLoans', result.data);
            }
            resolve(result);
          },
          (errors) => {
            reject(errors);
          },
        )
        .finally(() => {
          commit('setLoading', false);
        });
    });
  },
  link: ({ commit, rootGetters }, args) => {
    return new Promise((resolve, reject) => {
      let isPresto = rootGetters['company/isPresto'];
      args.company = isPresto ? 'Presto' : 'Cashtime';
      loanService.link(args).then(
        (result) => {
          if (result.succeeded) {
            commit('addLoan', result.data);
          }
          resolve(result);
        },
        (errors) => {
          reject(errors);
        },
      );
    });
  },
};

const mutations = {
  addLoan(state, loan) {
    if (loan) {
      state.loans.push(loan);
    }
  },
  addLoans(state, loans) {
    state.loans = loans;
  },
  clear: (s) => {
    s.loan = null;
    s.loans.splice(0, s.loans.length);
  },
  setLoading(s, val) {
    s.loading = val;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
