<template>
  <v-card>
    <v-card-title>
      {{ cardTitle }}
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-col>
          <p>
            All new loans are contingent on a final underwriting review. Any
            material changes to the conditions that led to your loan approval
            may impact our ability to process a new loan request. By clicking
            the “I Agree” button you certify the following statements
          </p>
          <div>
            <ul style="margin-bottom: 16px">
              <li>
                Vehicle(s) contracted in my loan agreement are still registered
                in my name
              </li>
              <li>
                Vehicle(s) are in running order and in the same condition as
                when inspected
              </li>
              <li>
                There have been no changes to my employment and reported income
              </li>
            </ul>
            <p>
              Please note you may be required to provide additional supporting
              documentation and to bring your vehicle in for a new inspection
              for final loan approval.
            </p>
          </div>
        </v-col>
      </v-container>
    </v-card-text>
    <v-card-actions class="pr-4">
      <v-spacer />
      <v-btn color="primary" class="mb-2" @click="$emit('nextstep')">
        I Agree
      </v-btn>
      <v-btn class="mb-2" @click="$emit('cancel')"> Cancel </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'LoanTypeAgreement',
  data() {
    return {
      valid: false,
    };
  },
  computed: {
    cardTitle() {
      return 'Loan Request';
    },
  },
};
</script>
