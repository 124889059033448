<template>
  <v-container fluid class="fill-height align-content-start pa-0">
    <v-overlay :value="showOverlay || showProgressOverlay" absolute>
      <v-progress-circular
        :size="70"
        :width="7"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </v-overlay>
    <centered-content-area>
      <v-slide-y-transition mode="out-in">
        <loan-type-agreement
          v-if="loan && step === 1"
          key="step1"
          :loan="loan"
          v-on:nextstep="goToNextStep"
          v-on:cancel="goToLoanDetails"
        ></loan-type-agreement>
        <select-amount
          :loan="loan"
          :min="sliderMin"
          :max="sliderMax"
          :increase-terms="increaseTerms"
          :loan-amount="selectedAmount"
          :term="selectedTerm"
          v-if="loan && step === 2 && sliderMin && sliderMax"
          :increase-amount="selectedAmount"
          v-on:amountchanged="selectedAmountChanged"
          v-on:termchanged="termChanged"
          key="step2"
          v-on:nextstep="goToNextStep"
          v-on:cancel="goToLoanDetails"
        ></select-amount>
        <personal-info
          :borrowerInfo="borrowerInfo"
          v-if="loan && step === 3"
          key="step3"
          v-on:nextstep="goToNextStep"
          v-on:cancel="goToLoanDetails"
          v-on:submit="submit"
          v-on:personalInfoUpdated="updatePersonalInfo"
          :showProgress="showProgress"
          :errors="errors"
        >
        </personal-info>
        <confirmation
          :loan="loan"
          key="step4"
          :requested-amount="selectedAmount"
          :term="selectedTerm"
          :confirmation-number="confirmationNumber"
          :borrowerInfo="borrowerInfo"
          v-if="step === 4"
          v-on:close="goToLoanDetails"
        >
        </confirmation>
      </v-slide-y-transition>
    </centered-content-area>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import CenteredContentArea from '../../components/CenteredContentArea.vue';
import LoanTypeAgreement from '../../components/ApplyNow/LoanTypeAgreement.vue';
import SelectAmount from '../../components/ApplyNow/SelectAmount.vue';
import PersonalInfo from '../../components/ApplyNow/PersonalInfo.vue';
import Confirmation from '../../components/ApplyNow/Confirmation.vue';
import { loanService } from '../../services/loan.service';
import { leadInjectionService } from '../../services/leadInjection.service';
import { math } from '../../mixins/math';

export default {
  name: 'ApplyNow',
  components: {
    SelectAmount,
    CenteredContentArea,
    PersonalInfo,
    Confirmation,
    LoanTypeAgreement,
  },
  mixins: [math],
  data() {
    return {
      loan: {
        loanType: 'Title',
      },
      selectedAmount: null,
      tempOnlineLeadId: null,
      sliderMin: 100,
      sliderMax: 10000,
      errors: [],
      step: 1,
      borrowerInfo: {
        firstName: null,
        lastName: null,
        accountPhoneNumber: null,
        email: null,
      },
      overlay: true,
      tempOnlineLeadIdLoading: true,
      termsLoading: true,
      increaseTerms: [],
      selectedTerm: null,
      confirmationNumber: null,
      employerAddressVerified: null,
      showProgress: false,
      showProgressOverlay: false,
    };
  },
  created() {
    this.$store.dispatch('vault/get').then(() => {
      this.init();
    });
    this.borrowerInfo.firstName = this.profile.firstName;
    this.borrowerInfo.lastName = this.profile.lastName;
    this.borrowerInfo.email = this.profile.email;
  },
  methods: {
    init() {
      var t = this;
      t.selectedAmount = 100;
      t.getIncreaseTerms(this.sliderMin, this.sliderMax);
      this.oauthStateId = this.$route.query.oauth_state_id;
      if (this.oauthStateId) {
        if (!this.loaded) {
          this.$store.dispatch('vault/get').then(() => {
            t.loadFromStorage();
          });
        } else {
          t.loadFromStorage();
        }
      }
    },
    onShowProgressOverlay(show) {
      this.showProgressOverlay = show;
    },
    updatePersonalInfo(args) {
      this.borrowerInfo.firstName = args.firstName;
      this.borrowerInfo.lastName = args.lastName;
      this.borrowerInfo.accountPhoneNumber = args.phone;
    },
    submit() {
      let args = {
        loanType: this.loan.loanType,
        firstName: this.borrowerInfo.firstName,
        lastName: this.borrowerInfo.lastName,
        phone: this.borrowerInfo.accountPhoneNumber,
        email: this.borrowerInfo.email,
        amountRequested: this.selectedAmount,
      };

      this.showProgress = true;
      leadInjectionService
        .submitApplyNow(args)
        .then((res) => {
          if (res.succeeded) {
            this.confirmationNumber = res.data;
            this.tempOnlineLeadId = String(res.data);
            this.goToNextStep();
          } else {
            this.errors = res.errors;
          }
        })
        .catch((err) => {
          console.error(err);
          this.errors.push(
            'An error occurred while attempting to process your request.',
          );
        })
        .finally(() => {
          this.showProgress = false;
        });
    },
    termChanged(term) {
      this.selectedTerm = term;
    },
    getIncreaseTerms(sliderMin, sliderMax) {
      this.termsLoading = true;
      let l = this.loan;
      var min = this.roundUpToNearestMultipleOf(50, sliderMin);
      var max = this.roundUpToNearestMultipleOf(50, sliderMax);
      loanService
        .getIncreaseTerms(l.loanType, min, max)
        .then((res) => {
          if (res.succeeded) {
            this.increaseTerms = res.data;
            this.$nextTick(() => {
              this.selectedTerm = this.getTerm();
            });
          } else {
            this.errors = res.errors;
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.termsLoading = false;
        });
    },
    getTerm() {
      let roundedLoanAmount = this.roundUpToNearestMultipleOf(
        50,
        this.selectedAmount,
      );

      for (var i = 0; i < this.increaseTerms.length; i++) {
        let term = this.increaseTerms[i];
        if (term.loanAmount === roundedLoanAmount) {
          return term;
        }
      }
      return null;
    },
    selectedAmountChanged(value) {
      this.selectedAmount = value;
      this.selectedTerm = this.getTerm();
    },
    goToNextStep() {
      this.step++;
    },
    goToLoanDetails() {
      this.$router.push({
        name: 'loanDetails',
      });
    },
  },
  computed: {
    ...mapGetters({
      key: 'vault/key',
      loaded: 'vault/key',
      profile: 'account/profile',
    }),
    showOverlay() {
      return this.termsLoading || !this.errors.length === 0;
    },
  },
  beforeDestroy() {
    this.$store.dispatch('vault/clear');
  },
};
</script>
