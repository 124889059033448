<template>
  <centered-content-area></centered-content-area>
</template>

<script>
import CenteredContentArea from '../components/CenteredContentArea';
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Dashboard',
  components: {
    CenteredContentArea,
  },
  created() {
    this.$router.push('/loan-details');
  },
};
</script>

<style lang="scss">
.emphasis {
  font-size: 1.18rem !important;
  font-weight: 500;
  line-height: 1.5rem;
  letter-spacing: 0.0125em !important;
  font-family: 'Roboto', sans-serif !important;
}
</style>
