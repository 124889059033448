<template>
  <v-form v-model="valid" @keyup.native.enter="submit">
    <v-card :loading="showProgress">
      <template slot="progress">
        <v-progress-linear color="accent" indeterminate></v-progress-linear>
      </template>
      <v-card-title>Personal Information</v-card-title>
      <v-card-text>
        <error-display :errors="errors"></error-display>
        <v-text-field
          label="First name"
          v-model="user.firstName"
          :rules="firstNameRules"
          required
          autofocus
        >
        </v-text-field>
        <v-text-field
          label="Last name"
          v-model="user.lastName"
          :rules="lastNameRules"
          required
        >
        </v-text-field>
        <masked-input
          :label="'Phone Number'"
          :option="{ blocks: [0, 3, 3, 4], delimiters: ['(', ') ', '-'] }"
          v-model="formattedPhone"
          v-on:raw="setRawPhoneValue"
          :numeric="true"
          :autocomplete="'tel'"
          :disabled="showProgress"
          :rules="phoneNumberRules"
        ></masked-input>
        <v-text-field
          label="Email"
          :type="'email'"
          readonly
          v-model="user.email"
          :disabled="true"
        >
        </v-text-field>
      </v-card-text>
      <v-card-actions class="pr-4 pt-0">
        <v-spacer />
        <v-btn
          color="primary"
          class="mb-2"
          @click="submit"
          :disabled="showProgress || !valid"
        >
          Continue
        </v-btn>
        <v-btn class="mb-2" @click="$emit('cancel')" :disabled="showProgress">
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { validationRules } from '../../mixins/validationRules';
import ErrorDisplay from '../ErrorDisplay.vue';
import MaskedInput from '../MaskedInput.vue';

export default {
  name: 'PersonalInfo',
  mixins: [validationRules],
  components: {
    ErrorDisplay,
    MaskedInput,
  },
  props: {
    borrowerInfo: {
      type: Object,
    },
    showProgress: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array,
    },
  },
  data() {
    return {
      valid: false,
      user: {
        email: null,
        firstName: null,
        lastName: null,
        phone: null,
      },
      formattedPhone: null,
      phoneNumberRules: [(v) => !!v || 'Phone number is required'],
    };
  },
  created() {
    this.user.firstName = this.borrowerInfo.firstName;
    this.user.lastName = this.borrowerInfo.lastName;
    this.user.email = this.borrowerInfo.email;
  },
  methods: {
    setRawPhoneValue(rawValue) {
      this.user.phone = rawValue;
    },
    submit() {
      this.$emit('personalInfoUpdated', this.user);
      // Precaution to prevent race condition failure
      setTimeout(() => {
        this.$emit('submit');
      }, 300);
    },
  },
};
</script>
