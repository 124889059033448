<template>
  <div>
    <v-navigation-drawer
      clipped
      app
      v-model="drawer"
      width="260"
      dark
      color="primary"
    >
      <v-list dense class="pt-0">
        <template v-for="item in menuItems">
          <!-- <v-list-group
            v-if="item.children"
            v-model="item.model"
            no-action
            :key="item.text"
          >
            <v-list-item slot="item" ripple>
              <v-list-item-action>
                <v-icon>{{ item.model ? item.icon : item['icon-alt'] }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.text }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-for="child in item.children"
              :key="child.text"
              ripple
              active-class="active-item"
            >
              <v-list-item-action v-if="child.icon">
                <v-icon>{{ child.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ child.text }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group> -->
          <v-list-item :to="item.route" :key="item.text" :exact="true">
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.text }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar :clipped-left="$vuetify.breakpoint.mdAndUp" fixed app>
      <v-toolbar-title>
        <v-btn icon @click.stop="drawer = !drawer" class="mr-3">
          <v-icon>menu</v-icon>
        </v-btn>
        <span class="logo-wrapper">
          <presto-logo v-if="isPresto" v-bind:height="38" v-bind:width="160" />
          <ct-logo v-else v-bind:height="38" v-bind:width="160" />
        </span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-btn text v-on="on">
              <img class="avatar" src="../assets/profile.jpg" />
              <span v-if="profile && $vuetify.breakpoint.mdAndUp" class="ml-2"
                >{{ profile.firstName }} {{ profile.lastName }}</span
              >
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="goTo('/profile')">
              <v-list-item-title>My Profile</v-list-item-title>
            </v-list-item>
            <v-list-item @click="logoff">
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar-items>
    </v-app-bar>
    <v-main class="fill-height">
      <v-slide-y-transition mode="out-in">
        <router-view></router-view>
      </v-slide-y-transition>
    </v-main>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CtLogo from '../components/CtLogo';
import PrestoLogo from '../components/PrestoLogo';

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Home',
  components: {
    CtLogo,
    PrestoLogo,
  },
  computed: {
    ...mapGetters({
      profile: 'account/profile',
      isPresto: 'company/isPresto',
    }),
  },
  data: () => {
    return {
      dialog: false,
      drawer: true,
      mini: true,
      menuItems: [
        {
          icon: 'list_alt',
          text: 'Loan Details',
          route: '/loan-details',
          requiresNoLinkedLoan: false,
        },
        {
          icon: 'email',
          text: 'Contact Us',
          route: '/contact-us',
          requiresNoLinkedLoan: false,
        },
        {
          icon: 'insert_link',
          text: 'Link My Loan',
          route: '/link-my-loan',
          requiresNoLinkedLoan: false,
        },
        {
          icon: 'location_on',
          text: 'Locations',
          route: '/locations',
          requiresNoLinkedLoan: false,
        },
      ],
    };
  },
  created() {
    if (this.$vuetify.breakpoint.xs) {
      this.drawer = false;
    }

    this.$vuetify.goTo(0);
  },
  methods: {
    goTo: function (route) {
      if (route) {
        this.$router.push(route);
      }
    },
    logoff: function () {
      this.$store.dispatch('auth/logOff').then(() => {
        this.$router.push('/login').catch(() => {});
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.logo-wrapper {
  height: 45px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  vertical-align: middle;
}
</style>

<style>
.pointer {
  cursor: pointer;
}
.avatar {
  height: 40px;
  width: 40px;
  border-radius: 50% !important;
}
.v-navigation-drawer {
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.35);
}
.v-navigation-drawer .v-list div a.v-list__tile--active {
  background-color: #039be5 !important;
  -webkit-transition: background-color 350ms linear;
  -moz-transition: background-color 350ms linear;
  -o-transition: background-color 350ms linear;
  -ms-transition: background-color 350ms linear;
  transition: background-color 350ms linear;
}
.v-navigation-drawer a.v-list__tile--active > div {
  color: #fff;
}
</style>
