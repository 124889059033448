<template>
  <v-card>
    <v-card-title> Thank you for choosing {{ companyName }}!</v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-col>
        <v-row>
          We are reviewing your application and will follow up with you shortly
          regarding next steps.
        </v-row>
      </v-col>
      <v-card flat outlined class="mt-2">
        <v-card-title> Confirmation </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-col>
            <v-row class="primary--text mt-3">Confirmation Number</v-row>
            <v-row class="font-weight-medium">{{ confirmationNumber }}</v-row>
            <v-row
              class="primary--text mt-3"
              v-if="
                borrowerInfo.vehicleCollateral &&
                borrowerInfo.vehicleCollateral.length > 0
              "
              >Vehicle Collateral</v-row
            >
            <v-row
              class="font-weight-medium"
              v-for="v in borrowerInfo.vehicleCollateral"
              :key="v"
            >
              {{ v }}
            </v-row>
            <v-row class="primary--text mt-3">* Estimated Loan Amount</v-row>
            <v-row class="font-weight-medium">{{
              requestedAmount | currency
            }}</v-row>

            <v-row class="primary--text mt-3">Amount Disbursed</v-row>
            <v-row class="font-weight-medium">{{
              requestedAmount | currency
            }}</v-row>
            <v-row class="primary--text mt-3">* Estimated Payment Amount</v-row>
            <v-row class="font-weight-medium">{{
              term.estimatedPayment | currency
            }}</v-row>

            <v-row class="primary--text mt-3"
              >* Estimated Total Finance Charges Paid</v-row
            >
            <v-row class="font-weight-medium">{{
              term.estimatedTotalFinanceChargesPaid | currency
            }}</v-row>
            <v-row class="primary--text mt-3">* Estimated APR</v-row>
            <v-row class="font-weight-medium">{{ term.estimatedApr }}%</v-row>

            <v-row class="mt-5">
              <span class="pt-1">
                * APR and account terms are estimates. Actual APR and payment
                will vary based on terms.
                <info-dialog>
                  <template v-slot:title> Estimated Terms </template>
                  <template v-slot:text>
                    Account Terms and APR displayed represent an example. We had
                    to estimate the Annual Percentage Rate, Total Finance
                    Charges Paid, and Total Number of Payments because we do not
                    know exactly the number of days to first payment or when the
                    proceeds of the Loan will be disbursed to you. Banking
                    delays or holidays, delays in making contact with you to
                    verify the information you have provided to us or delays in
                    signing your loan documents may cause the Loan proceeds to
                    be disbursed later. Your actual account terms will be
                    provided in your loan documents for review prior to signing
                    your contract.
                  </template>
                </info-dialog>
              </span>
            </v-row>
            <name-value-pair-row
              v-if="location"
              class="mt-4 pb-0 text-h6 primary--text font-weight-bold"
              name="Contact Store"
              :value="location.phone | formatPhone"
            >
            </name-value-pair-row>
          </v-col>
        </v-card-text>
      </v-card>
      <v-card-actions class="mt-2">
        <v-spacer />
        <v-btn color="primary" @click="$emit('close')"> Close </v-btn>
      </v-card-actions>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import InfoDialog from '../../dialogs/InfoDialog.vue';
import NameValuePairRow from '../NameValuePairRow.vue';
export default {
  components: { InfoDialog, NameValuePairRow },
  name: 'IncreaseEligibleConfirmation',
  props: [
    'loan',
    'requestedAmount',
    'borrowerInfo',
    'confirmationNumber',
    'term',
  ],
  data() {
    return {
      location: null,
    };
  },
  created() {
    this.location = this.getLocationById(this.loan.locationId);
  },
  computed: {
    ...mapGetters({
      companyName: 'company/name',
      getLocationById: 'location/getLocationById',
    }),
  },
};
</script>
