<template>
  <v-container fluid class="fill-height align-content-start">
    <v-overlay :value="showOverlay" absolute>
      <v-progress-circular
        :size="70"
        :width="7"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </v-overlay>
    <centered-content-area
      v-if="!loansLoading && (loans == null || loans.length == 0)"
    >
      <account-is-ready class="mb-4"></account-is-ready>
    </centered-content-area>
    <v-row
      v-show="loans !== null && loans.length > 0"
      align="center"
      justify="center"
      v-for="loan in loans"
      :key="loan.loanNumber"
    >
      <v-col cols="12" sm="8" md="6" lg="5" xl="3">
        <increase-eligible-widget
          :loan="loan"
          v-if="loan.increaseEligibleAmount > 0"
        ></increase-eligible-widget>
        <loan-detail-card :loan="loan"></loan-detail-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import IncreaseEligibleWidget from '../components/IncreaseEligibleWidget';
import LoanDetailCard from '../components/LoanDetailCard';
import AccountIsReady from '../components/AccountIsReady.vue';
import CenteredContentArea from '../components/CenteredContentArea.vue';
export default {
  name: 'LoanDetails',
  components: {
    LoanDetailCard,
    IncreaseEligibleWidget,
    AccountIsReady,
    CenteredContentArea,
  },
  data() {
    return {
      loaded: false,
      overlay: false,
      overlayTimeoutComplete: false,
    };
  },
  computed: {
    ...mapGetters({
      token: 'auth/token',
      isPresto: 'company/isPresto',
      locationsLoaded: 'location/loaded',
      loans: 'loan/loans',
      loansLoading: 'loan/loading',
    }),
    showOverlay() {
      return (
        this.overlay ||
        !this.locationsLoaded ||
        this.loansLoading ||
        !this.overlayTimeoutComplete
      );
    },
  },
  created() {
    this.$store.dispatch('location/getLocations');
    this.$store.dispatch('loan/getLoans');
    setTimeout(() => {
      this.overlayTimeoutComplete = true;
    }, 300);
  },
};
</script>
