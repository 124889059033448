<template>
  <v-card>
    <v-card-title>Estimated Loan Terms</v-card-title>
    <v-card-text>
      <v-container class="mb-2">
        <v-row align="center" justify="center" class="pb-3">
          <v-card flat outlined>
            <v-card-title style="font-size: 16px" class="pa-0">
              <v-container style="min-width: 135px">
                <v-row justify="center">
                  <v-col cols="9">
                    <div class="number-display">
                      {{ selectedAmount | currency }}
                    </div>
                  </v-col>
                  <v-col
                    cols="3"
                    class="d-flex flex-column align-end justify-center"
                  >
                    <div class="square-button" @click="incrementAmount">
                      <v-icon small>mdi-arrow-up</v-icon>
                    </div>
                    <div class="square-button" @click="decrementAmount">
                      <v-icon small>mdi-arrow-down</v-icon>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-title>
          </v-card>
        </v-row>
      </v-container>
      <v-row class="pl-3 pr-4">
        <v-slider
          id="select-amount"
          :min="min"
          :max="max"
          v-model="selectedAmount"
          :step="increment"
        >
          <template v-slot:prepend>
            <span>{{ min | currency }}</span>
          </template>
          <template v-slot:append>
            <span>{{ max | currency }}</span>
          </template>
        </v-slider>
      </v-row>
      <v-card flat outlined class="mt-3">
        <v-card-subtitle class="subtitle-1 font-weight-bold">
          New Account Terms
        </v-card-subtitle>
        <v-divider class="pb-1" />
        <v-card-text v-if="term">
          <name-value-pair-row
            :name="'Number of Payments'"
            :value="String(term.numberOfPayments)"
          ></name-value-pair-row>
          <v-divider class="mb-5 mt-2" />
          <name-value-pair-row
            :name="'Estimated Payment'"
            :value="term.estimatedPayment | currency"
          ></name-value-pair-row>
          <name-value-pair-row
            :name="'Estimated Total Finance Charges Paid'"
            :value="term.estimatedTotalFinanceChargesPaid | currency"
          ></name-value-pair-row>
          <name-value-pair-row
            :name="'* Estimated APR'"
            :value="EstimatedApr"
          ></name-value-pair-row>
          <v-divider class="mb-5 mt-2" />
          <name-value-pair-row
            :name="'Cash Paid to Borrower'"
            :value="increaseAmount | currency"
          ></name-value-pair-row>
          <v-divider class="mb-5 mt-2" />
          <name-value-pair-row
            :name="'New Loan Principal Balance'"
            :value="loanAmount | currency"
          >
          </name-value-pair-row>
          <name-value-pair-row
            class="mt-4"
            name="* APR and account terms are for example purposes and are an
            estimate. Your actual APR and account terms will be provided in your
            loan documents and should be reviewed prior to signing your loan
            documents."
          >
            <template v-slot:name-append>
              <info-dialog>
                <template v-slot:title> Estimated Terms </template>
                <template v-slot:text>
                  Account Terms and APR displayed represent an example. We had
                  to estimate the Annual Percentage Rate, Total Finance Charges
                  Paid, and Total Number of Payments because we do not know
                  exactly the number of days to first payment or when the
                  proceeds of the Loan will be disbursed to you. Banking delays
                  or holidays, delays in making contact with you to verify the
                  information you have provided to us or delays in signing your
                  loan documents may cause the Loan proceeds to be disbursed
                  later. Your actual account terms will be provided in your loan
                  documents for review prior to signing your contract.
                </template>
              </info-dialog>
            </template>
          </name-value-pair-row>
        </v-card-text>
      </v-card>
    </v-card-text>
    <v-card-actions class="pr-4">
      <v-spacer />
      <v-btn color="primary" class="mb-2" @click="$emit('nextstep')">
        Continue
      </v-btn>
      <v-btn class="mb-2" @click="$emit('cancel')"> Cancel </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import NameValuePairRow from '../NameValuePairRow.vue';
import InfoDialog from '../../dialogs/InfoDialog.vue';
import { math } from '../../mixins/math';
export default {
  components: { NameValuePairRow, InfoDialog },
  name: 'SelectAmount',
  props: [
    'loan',
    'increaseAmount',
    'min',
    'max',
    'increaseTerms',
    'loanAmount',
    'term',
  ],
  mixins: [math],
  created() {
    this.selectedAmount = this.min;
  },
  mounted() {
    window.addEventListener('keydown', this.handleKeyDown);
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.handleKeyDown);
  },
  data() {
    return {
      valid: false,
      selectedAmount: 100,
      maxAmount: 10000,
      minAmount: 100,
      increment: 50,
    };
  },
  methods: {
    handleKeyDown(event) {
      if (event.key === 'ArrowUp') {
        this.incrementAmount();
      } else if (event.key === 'ArrowDown') {
        this.decrementAmount();
      }
    },
    incrementAmount() {
      if (this.selectedAmount < this.maxAmount) {
        this.selectedAmount += this.increment;
      }
    },
    decrementAmount() {
      if (this.selectedAmount > this.minAmount) {
        this.selectedAmount -= this.increment;
      }
    },
  },
  computed: {
    EstimatedApr() {
      return `${this.term.estimatedApr}%`;
    },
  },
  watch: {
    selectedAmount: function (n) {
      this.$emit('amountchanged', n);
    },
  },
};
</script>

<style scoped>
.number-display {
  text-align: center;
  line-height: 40px;
}

.square-button {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
  background-color: #f5f5f5;
  cursor: pointer;
}

.square-button:last-child {
  margin-bottom: 0; /* Remove margin for the last button */
}
</style>

<style lang="scss">
.v-slider__thumb {
  height: 19px;
  width: 19px;
}

.v-slider--horizontal .v-slider__track-container {
  height: 5px;
}

.v-slider__track-background {
  border-radius: 2px;
}
.v-slider__thumb:after {
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  content: '';
  color: inherit;
  width: 400%;
  height: 400%;
  border-radius: 50%;
  background: transparent;
  position: absolute;
  left: -150%;
  top: -150%;
}

.v-slider__thumb:before {
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  content: '';
  color: inherit;
  width: 200%;
  height: 200%;
  border-radius: 50%;
  background: currentColor;
  opacity: 0.3;
  position: absolute;
  left: -50%;
  top: -50%;
  transform: scale(0.1);
  pointer-events: none;
}

.v-input__prepend-outer {
  padding-top: 4px;
}
.v-input__append-outer {
  padding-top: 4px;
}
</style>
