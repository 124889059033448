import axios from 'axios';
import { BaseService } from './base.service';

class LeadInjectionService extends BaseService {
  static get Instance() {
    // Do you need arguments? Make it a regular method instead.
    return this.instance || (this.instance = new this());
  }

  updateLeadSteps(args) {
    return axios
      .post(`${this.api}/leads/update-steps`, args)
      .then((res) => res.data)
      .catch((error) => this.handleError(error.response));
  }

  submitIncrease(args) {
    return axios
      .post(`${this.api}/leads/increase`, args)
      .then((res) => res.data)
      .catch((error) => this.handleError(error.response));
  }

  submitApplyNow(args) {
    return axios
      .post(`${this.api}/leads/apply-now`, args)
      .then((res) => res.data)
      .catch((error) => this.handleError(error.response));
  }
}
// export a singleton instance in the global namespace
export const leadInjectionService = LeadInjectionService.Instance;
